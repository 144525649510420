module.exports = [
  {
    id: 0,
    name: 'edit',
    component: 'edit'
  },
  {
    id: 1,
    name: 'review',
    component: 'review'
  },
  {
    id: 2,
    name: 'approve',
    component: 'approve'
  },
  {
    id: 10,
    name: 'publish',
    component: 'publish'
  },
  {
    id: -1,
    name: 'delete',
    component: 'delete'
  }
]
