<template>
<div>
  <b-nav tabs>
    <b-nav-item :active="active.workflow" :to="{ name: 'WorkflowArticles' }">WIP</b-nav-item>
    <b-nav-item :active="active.personal" :to="{ name: 'WorkflowArticlesPersonal' }">Personal WIP</b-nav-item>
    <b-nav-item :active="active.starrednews" :to="{ name: 'WorkflowArticlesStarredNews' }">Starred News</b-nav-item>
    <b-nav-item :active="active.published" :to="{ name: 'WorkflowArticlesPublished' }">Published</b-nav-item>
    <b-nav-item :active="active.deleted" :to="{ name: 'WorkflowArticlesDeleted' }">Deleted</b-nav-item>
    <b-nav-item :active="active.journal" :to="{ name: 'WorkflowArticlesJournal' }">Journal</b-nav-item>
    <b-nav-item :active="active.dashboard" :to="{ name: 'WorkflowArticlesDashboard' }">Dashboard</b-nav-item>
    <b-nav-item :active="active.diagram" :to="{ name: 'WorkflowArticlesDiagram' }">Diagram</b-nav-item>
  </b-nav>
</div>
</template>

<script>
// import ac from '@/libs/accesscontrol'

export default {
  props: ['activekey'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.active[this.activekey] = true
  },
  data () {
    return {
      active: {
        journal: false,
        workflow: false
      }
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
