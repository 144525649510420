<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        <b-container class="bg-white">
          <b-row class="mb-4">
            <b-col>
              <menu-workflow :activekey="'workflow'" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span v-if="permissions.addArticle"><b-button class="mr-2" variant="outline-primary" v-on:click="addArticle">new article</b-button></span>
              <!-- <span><b-button variant="outline-primary" class="mr-2" @click="download">download</b-button></span> -->
              <b-button variant="outline-primary" v-b-toggle.excel>view all</b-button>
            </b-col>
          </b-row>
          <b-row class="py-4">
            <b-col>
              <h3>Writers</h3>
              <div class="my-4" v-for="group in articlesByUser" :key="'articles-reviewers-group' + group.user">
                <h5>{{group.user}}: {{group.articles.length}}</h5>
              </div>
            </b-col>
            <b-col>
              <h3>Reviewers</h3>
              <div class="my-4" v-for="group in articlesByReviewer" :key="'articles-reviewers-group' + group.user">
                <h5>{{group.user}}: {{group.articles.length}}</h5>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group horizontal class="mt-4">
                <b-input-group>
                  <b-form-input v-model="searchTerm" placeholder="Type to Search" />
                  <b-btn :disabled="!searchTerm" @click="searchTerm = ''">clear</b-btn>
                </b-input-group>
              </b-form-group>
              <b-form inline class="mb-4">
                <label class="mr-2">sort by</label>
                <b-form-select class="mr-2" v-model="sortBy" :options="sortByOptions" />
                <b-form-select class="mr-2" v-model="sortOrder" :options="sortOrderOptions" />
                <label class="mr-2">filter stage</label>
                <b-form-select class="mr-2" v-model="stageFilter" :options="stageFilterOptions" />
              </b-form>
            </b-col>
          </b-row>
          <b-row v-if="this.articlesOverdue.length > 0" class="py-4 bg-warning">
            <b-col>
              <h3>Overdue</h3>
            </b-col>
          </b-row>
          <b-row class="pb-4 bg-warning" v-for="article in articlesOverdue" :key="'article-overdue-' + article.id">
            <b-col>
              <b-container>
                <b-row>
                  <b-col>
                    <h3 style="font-size: 14px;"><router-link  :to="{ name: 'WorkflowArticle', params: { id: article.id } }" >[{{article.id}}] {{article.title}}</router-link></h3>
                  </b-col>
                </b-row>
                <b-row style="font-size: 12px">
                  <b-col>
                    stage: {{workflow.find(x => x.id === article.stage).name}}<span v-if="article.stage_due_date">, due on: {{moment(article.stage_due_date).format('YYYY-MM-DD')}}</span> |
                    created by {{article.created_by}} at {{moment(article.created_at).format('YYYY-MM-DD HH:mm')}} |
                    <span v-if="article.users.length >0">
                      assigned to: <span v-for="user in article.users" :key="'article-overdue-user-'+ user.id">{{user.username}} </span>
                    </span>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row v-if="this.articlesOverdue.length > 0" class="my-4">
            <b-col>
              <h3>All</h3>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-for="article in articlesData" :key="'article-' + article.id">
            <b-col>
              <b-container>
                <b-row>
                  <b-col>
                    <h3 style="font-size: 14px;"><router-link  :to="{ name: 'WorkflowArticle', params: { id: article.id } }" >[{{article.id}}] {{article.title}}</router-link></h3>
                  </b-col>
                </b-row>
                <b-row style="font-size: 12px">
                  <b-col>
                    stage: {{workflow.find(x => x.id === article.stage).name}}<span v-if="article.stage_due_date">, due on: {{moment(article.stage_due_date).format('YYYY-MM-DD')}}</span> |
                    created by {{article.created_by}} at {{moment(article.created_at).format('YYYY-MM-DD HH:mm')}} |
                    <span v-if="article.users.length >0">
                      writers to: <span v-for="user in article.users" :key="'article-all-user-'+ user.id">{{user.username}} </span> |
                    </span>
                    <span v-if="article.reviewers.length >0">
                      reviewers to: <span v-for="user in article.reviewers" :key="'article-all-reviewer-'+ user.id">{{user.username}} </span>
                    </span>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="py-4 bg-info">
            <b-col>
              <h3>Articles by writer</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="my-4" v-for="group in articlesByUser" :key="'articles-grouol' + group.user">
                <h4>{{group.user}}: {{group.articles.length}}</h4>
                <div class="mb-4" v-for="article in group.articles" :key="'article-in-user-'+article.id">
                  <h3 style="font-size: 14px;"><router-link  :to="{ name: 'WorkflowArticle', params: { id: article.id } }" >[{{article.id}}] {{article.title}}</router-link></h3>
                  <div>
                    stage: {{workflow.find(x => x.id === article.stage).name}}<span v-if="article.stage_due_date">, due on: {{moment(article.stage_due_date).format('YYYY-MM-DD')}}</span> |
                    created by {{article.created_by}} at {{moment(article.created_at).format('YYYY-MM-DD HH:mm')}} |
                    <span v-if="article.users.length >0">
                      assigned to: <span v-for="user in article.users" :key="'article-overdue-user-'+ user.id">{{user.username}} </span>
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="py-4 bg-info">
            <b-col>
              <h3>Articles by reviewer</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="my-4" v-for="group in articlesByReviewer" :key="'articles-reviewers-group' + group.user">
                <h4>{{group.user}}: {{group.articles.length}}</h4>
                <div class="mb-4" v-for="article in group.articles" :key="'article-in-user-'+article.id">
                  <h3 style="font-size: 14px;"><router-link  :to="{ name: 'WorkflowArticle', params: { id: article.id } }" >[{{article.id}}] {{article.title}}</router-link></h3>
                  <div>
                    stage: {{workflow.find(x => x.id === article.stage).name}}<span v-if="article.stage_due_date">, due on: {{moment(article.stage_due_date).format('YYYY-MM-DD')}}</span> |
                    created by {{article.created_by}} at {{moment(article.created_at).format('YYYY-MM-DD HH:mm')}} |
                    <span v-if="article.users.length >0">
                      assigned to: <span v-for="user in article.users" :key="'article-overdue-user-'+ user.id">{{user.username}} </span>
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>

     <b-sidebar id="excel" width="100%" title="View all (experimental)" text-variant="black" shadow>
      <div class="px-3 py-2">
          <div v-for="article in articlesData" :key="'article-table-' + article.id">
            <h1>{{article.title}}</h1>
            <div v-html="article.body"></div>
          </div>
      </div>
    </b-sidebar>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import zipcelx from 'zipcelx'

import ac from '@/libs/accesscontrol'
import workflow from '@/workflows/article'

import MenuWorkflow from '@/components/workflow/article/Menu.vue'

export default {
  components: {
    MenuWorkflow
  },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    },
    articlesByUser: function () {
      const users = []
      _.each(this.articlesData, x => {
        if (x.users.length > 0) {
          _.each(x.users, y => {
            users.push(y.username)
          })
        }
      })
      const usersUniq = _.uniq(users).sort()
      const response = []
      _.each(usersUniq, user => {
        const temp = { user: user, articles: [] }
        _.each(this.articlesData, article => {
          if (article.users.length > 0) {
            _.each(article.users, x => {
              if (x.username === user) {
                temp.articles.push(article)
              }
            })
          }
        })
        response.push(temp)
      })
      return response
    },
    articlesByReviewer: function () {
      const users = []
      _.each(this.articlesData, x => {
        if (x.reviewers.length > 0) {
          _.each(x.reviewers, y => {
            users.push(y.username)
          })
        }
      })
      const usersUniq = _.uniq(users).sort()
      const response = []
      _.each(usersUniq, user => {
        const temp = { user: user, articles: [] }
        _.each(this.articlesData, article => {
          if (article.reviewers.length > 0) {
            _.each(article.reviewers, x => {
              if (x.username === user) {
                temp.articles.push(article)
              }
            })
          }
        })
        response.push(temp)
      })
      return response
    },
    articlesData: function () {
      let temp = this.articles
      // filter
      if (this.stageFilter !== 'all') {
        temp = _.filter(this.articles, ['stage', this.stageFilter])
      }
      // sort
      if (this.sortOrder === 'asc') {
        temp = _.sortBy(temp, [this.sortBy])
      } else {
        temp = _.reverse(_.sortBy(temp, [this.sortBy]))
      }
      // search
      if (this.searchTerm.length > 2) {
        temp = temp.filter(item => item.title.toUpperCase().includes(this.searchTerm.toUpperCase()))
      }
      return temp
    },
    articlesOverdue: function () {
      return _.filter(this.articlesData, x => {
        if (x.stage_due_date === null) {
          return false
        } else {
          if (moment().isAfter((x.stage_due_date))) {
            return true
          } else {
            return false
          }
        }
      })
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Articles Admin"
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    this.permissions.addArticle = ac.can(this.user.acgroups).readAny('articleWorkflowEdit').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
    _.each(workflow, x => {
      let push = true
      if (x.name === 'publish') {
        push = false
      }
      if (x.name === 'delete') {
        push = false
      }
      if (push) {
        this.stageFilterOptions.push({ value: x.id, text: x.name })
      }
    })
    this.articles = await this.$Amplify.API.get('cosmos', '/articles/wip/full')
  },
  data () {
    return {
      articles: [],
      articlesShow: [],
      articlesTemp: [],
      searchTerm: '',
      moment: moment,
      permissions: {},
      sortBy: 'id',
      sortByOptions: [
        { value: 'id', text: 'id' },
        { value: 'title', text: 'title' },
        { value: 'created_by', text: 'creator' },
        { value: 'stage_due_date', text: 'due date' }
      ],
      sortOrder: 'dsc',
      sortOrderOptions: [
        { value: 'asc', text: 'ascending' },
        { value: 'dsc', text: 'descending' }
      ],
      stageFilter: 'all',
      stageFilterOptions: [
        { value: 'all', text: 'all' }
      ],
      workflow: workflow
    }
  },
  methods: {
    addArticle: async function () {
      try {
        const config = {
          article: {
            title: 'New article',
            summary: 'Provide a short one paragraph summary',
            body: '<p>work in progress...</p>',
            created_by: this.user.username
          }
        }
        let params = {
          body: config
        }
        let response = await this.$Amplify.API.put('cosmos', '/article', params)
        this.$stat.log({ page: 'articles', action: 'created article', model: 'article', model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        this.$router.push({ name: 'WorkflowArticle', params: { id: response.id } })
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    download: async function () {
      this.$logger.debug('loading started')
      let data = []
      const headings = [
        { value: 'Title', type: 'string' },
        { value: 'Body', type: 'string' }
      ]
      data.push(headings)
      _.each(this.articlesData, x => {
        data.push([
          { value: x.title, type: 'string' },
          { value: x.body, type: 'string' }
        ])
      })
      const config = { filename: 'article', sheet: { data: data } }
      zipcelx(config)
    }
  },
  watch: {
  }
}
</script>

<style>
.export-table table {
 border: solid;
}
</style>
